import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import { Button, Input, Select } from "antd";
import { FItem } from "./interioriDesignListingStyle";
import { green } from "@material-ui/core/colors";
import { API } from "../../Services";
import toast from "react-hot-toast";

const EditUtecProjectModal = ({
  isOpen,
  onClose,
  data,
  categories,
  subCategories,
  utecProjectList,
  formData,
  current,
}) => {
  const [width, setWidth] = useState(data.width);
  const [depth, setDepth] = useState(data.depth);
  const [buildUpArea, setArea] = useState(data.buildUpArea);
  const [folderLink, setFolderLink] = useState(data.folderPath);
  const [direction, setDirection] = useState(data.direction);
  const [floors, setFloors] = useState(data.floors);

  const { Option } = Select;
  const directions = [
    { value: "EE", label: "East " },
    { value: "WW", label: "West" },
    { value: "NN", label: "North" },
    { value: "SS", label: "South" },
    { value: "NE", label: "North-East" },
    { value: "NW", label: "North-West" },
    { value: "SE", label: "South-East" },
    { value: "SW", label: "South-West" },
  ];

  useEffect(() => {
    console.log("data", data);
    setWidth(data.width);
    setDepth(data.depth);
    setArea(data.buildUpArea);
    setFolderLink(data.folderPath);
    setDirection(data?.direction);
    setFloors(data?.floors);
  }, [data]);

  const handleSave = () => {
    // Call API to update interior design details
    console.log("Updated data:", {
      width,
      depth,
      buildUpArea,
      folderLink,
    });
    let fdata = {
      width: width,
      depth: depth,
      buildUpArea: buildUpArea,
      folderName: folderLink,
      direction: direction,
      floors: floors,
    };
    API.updateUtecDetail(data?.projectID, fdata)
      .then((res) => {
        toast.success("Utec Project details are updated successfully.", {
          position: "top-right",
        });
       

        let url = `limit=10&offset=0&page=1`;
        utecProjectList(url);
                onClose();

      })
      .catch((err) => {
        toast.error(
          `Oops!,${err || "Utec Project details are not updated."}!`,
          {
            position: "top-right",
          }
        );
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered // Centers the modal
    >
      <ModalOverlay />
      <ModalContent style={{ margin: "1rem", width: "85%" }}>
        <ModalCloseButton fontSize={"1rem"} backgroundColor={green} />

        <ModalBody>
          <h2
            style={{
              padding: "0.5rem 1rem",
              fontWeight: "600",
              fontSize: "large",
            }}
          >
            Edit Design Information
          </h2>

          <div style={{ padding: "1.2rem" }}>
            <FItem>
              <span className="f-check-group">
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Width</FormLabel>

                  <Input
                    allowClear
                    placeholder="Width..."
                    name="width"
                    value={width}
                    onChange={(event) => setWidth(event.target.value)}
                  />
                </FormControl>
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Depth</FormLabel>
                  <Input
                    allowClear
                    placeholder="Depth..."
                    name="depth"
                    value={depth}
                    onChange={(event) => setDepth(event.target.value)}
                  />{" "}
                </FormControl>
              </span>
            </FItem>
            <FItem>
              <span className="f-check-group">
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Area</FormLabel>
                  <Input
                    allowClear
                    placeholder="buildUpArea..."
                    name="buildUpArea"
                    value={buildUpArea}
                    onChange={(event) => setArea(event.target.value)}
                  />
                </FormControl>
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Floors</FormLabel>
                  <Input
                    allowClear
                    placeholder="floors..."
                    name="floors"
                    value={floors}
                    onChange={(event) => setFloors(event.target.value)}
                  />
                </FormControl>
              </span>
            </FItem>
            <FItem>
              <span className="f-check-group">
                <FormControl as="fieldset" className="f-input-group">
                  <FormLabel className="f-lable">Direction</FormLabel>

                  <Select
                    size="medium"
                    value={direction} // Convert width to string
                    onChange={(value) => setDirection(value)}
                    placeholder="Select Width"
                    style={{ width: "98%" }}
                  >
                    {directions.map((row, rowIndex) => (
                      <Option value={row.value} key={rowIndex}>
                        {row.label}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              </span>
            </FItem>
            <FItem>
              <FormControl as="fieldset">
                <FormLabel className="f-lable">Folder Name :</FormLabel>
                <Input
                  allowClear
                  value={folderLink}
                  placeholder="Enter Folder Path..."
                  name="folderName"
                  style={{ width: "95%" }}
                  onChange={(e) => setFolderLink(e.target.value)}
                />
                <br />
                <span style={{ fontStyle: "italic", fontSize: "12px" }}>
                  *Path Format Should Be Like: '&#40;project
                  ID&#41;/&#40;width&#41;/&#40;depth&#41;/&#40;direction&#41;/&#40;area&#41;/&#40;floor&#41;'
                </span>
              </FormControl>
            </FItem>
            <br />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave}>Update</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditUtecProjectModal;
